import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const jobTitleValues = [
  {value: "Administrative Pastor"},
  {value: "Campus Pastor"},
  {value: "Children’s Pastor"},
  {value: "College Pastor"},
  {value: "Community Pastor"},
  {value: "Connections Pastor"},
  {value: "Creative Arts Pastor"},
  {value: "Discipleship Pastor"},
  {value: "Executive Pastor"},
  {value: "Family Pastor"},
  {value: "High School Pastor"},
  {value: "Leadership Development Pastor"},
  {value: "Middle School Pastor"},
  {value: "Missions Pastor"},
  {value: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor"},
  {value: "Small Group Pastor"},
  {value: "Stewardship Pastor"},
  {value: "Student Pastor"},
  {value: "Teacher Pastor"},
  {value: "Worship Pastor"},
  {value: "Administrative Assistant"},  
  {value: "AV Director"},
  {value: "Bookkeeper"},
  {value: "Chief Financial Officer"},
  {value: "Children’s Ministry Coordinator"},
  {value: "Communications Coordinator"},
  {value: "Communications Director"},
  {value: "Communications Project Manager"},
  {value: "Content Writer"},
  {value: "Digital Media Analyst"},
  {value: "Events Coordinator"},
  {value: "Facility Manager"},
  {value: "Graphic Designer"},
  {value: "Groups Director"},
  {value: "Guest Services Director"},
  {value: "Human Resources Coordinator"},
  {value: "IT Director"},
  {value: "Mission and Outreach Coordinator"},
  {value: "Office Manager"},
  {value: "Online Community Manager"},
  {value: "Pastoral Care Minister"},
  {value: "Preschool Director"},
  {value: "Production Coordinator"},
  {value: "Social Media Manager"},
  {value: "Video Producer"},
  {value: "Volunteer Coordinator"},
  {value: "Web Developer"},
  {value: "Worship Leader"}
];

const givingProviderValues = [
  {value: "Continue To Give"},
  {value: "Donorbox"},
  {value: "easyTithe"},
  {value: "Givelify"},
  {value: "Kindred"},
  {value: "MoGiv"},
  {value: "OnlineGiving"},
  {value: "PayPal"},
  {value: "Planning Center"},
  {value: "Pushpay"},
  {value: "RebelGive"},
  {value: "SecureGive"},
  {value: "Tithe.ly"},
  {value: "Other"},
  {value: "None"}
];

const Details = (props) => {

  let navigate                                = useNavigate();
  const { id }                                = useParams();
  const [searchParams, setSearchParams]       = useSearchParams();

  const [isSaving, setIsSaving]               = React.useState(false);
  const [isLoading, setIsLoading]             = React.useState(false);
  const [isFormValid, setIsFormValid]         = React.useState(false);
  const [isMember, setIsMember]               = React.useState(true);

  const [firstName, setFirstName]             = React.useState("");
  const [lastName, setLastName]               = React.useState("");
  // const [fullName, setFullName]               = React.useState("");
  const [email, setEmail]                     = React.useState("");
  const [jobTitle, setJobTitle]               = React.useState("");
  const [churchName, setChurchName]           = React.useState("");
  const [givingProvider, setGivingProvider]   = React.useState("none");
  const [zipCode, setZipCode]                 = React.useState("");

  const [firstNameError, setFirstNameError]   = React.useState(false);
  const [lastNameError, setLastNameError]     = React.useState(false);
  // const [fullNameError, setFullNameError]     = React.useState(false);
  const [emailError, setEmailError]           = React.useState(false);
  const [jobTitleError, setJobTitleError]     = React.useState(false);
  const [churchNameError, setChurchNameError] = React.useState(false);
  const [zipCodeError, setZipCodeError]       = React.useState(false);
  const [givingError, setGivingError]         = React.useState(false);

  useEffect(() => {
    // document.body.style.backgroundColor = "#52BB66";
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
  }, []);

  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        // setFullName(data.fullName);
        setEmail(data.email);
        setJobTitle("none");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  // const updateFullName = (event) => {
  //   setFullName(event.target.value);
  //   checkFormValid();
  // }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const updateJobTitle = (event) => {
    console.log(event.target.value);
    setJobTitle(event.target.value);
    checkFormValid();
  }

  const updateGivingProvider = (event) => {
    console.log(event.target.value);
    setGivingProvider(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if(isMember) {
      if(firstName != "" && lastName != "" && jobTitle != "") {
      // if(fullName != "") {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if(firstName != "" && lastName != "" && email != "" && jobTitle != "" && churchName != "" && zipCode != "") {
      // if(fullName != "" && email != "" && jobTitle != "none" && churchName != "" && zipCode != "" && givingProvider != "") {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(firstName == "") {
      setFirstNameError(true);
    }
    if(lastName == "") {
      setLastNameError(true);
    }
    // if(fullName == "") {
    //   setFullNameError(true);
    //   // setTimeout(() => { console.log(firstNameError); }, 2000);
    // }
    if(email == "") {
      setEmailError(true);
    }
    if(jobTitle == "") {
      setJobTitleError(true);
    }
    if(churchName == "") {
      setChurchNameError(true);
    }
    if(zipCode == "") {
      setZipCodeError(true);
    }
    if(givingProvider == "none") {
      setGivingError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "jobTitle": jobTitle,
        "churchName": churchName,
        "zipCode": zipCode
      },
      formVersion: 2
    });

    // let jsonBody        = JSON.stringify({
    //   details: {
    //     "fullName": fullName,
    //     "email": email,
    //     "jobTitle": jobTitle,
    //     "churchName": churchName,
    //     "zipCode": zipCode,
    //     "givingProvider": givingProvider
    //   },
    //   formVersion: 2
    // });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");


    if(isMember && firstName != "" && lastName != "" && jobTitle != "none") {
    // if(isMember && fullName != "" && jobTitle != "none") {
      submitToApi();
    } else if(!isMember && firstName != "" && lastName != "" && email != "" && jobTitle != "none" && churchName != "" && zipCode != "") {
    // } else if(!isMember && fullName != "" && email != "" && jobTitle != "none" && churchName != "" && zipCode != "" && givingProvider != "") {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{'color': '#fff'}} size={23} /></div>
    }
    return "Show me my results";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    // if(field == "fullName") {
    //   if(fullNameError) {
    //     return <div className="error-text">We need your full name!</div>
    //   }
    // }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please confirm your job title.</div>
      }
    }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please give us your Church/Organization name.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">We’ll need your ZIP code.</div>
      }
    }
    if(field == "givingProvider") {
      if(givingError) {
        return <div className="error-text">Please make a selection.</div>
      }
    }
  }

  const removeHintText = (field) => {
    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    // if(field == "fullName") {
    //   setFullNameError(false);
    // }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
    if(field == "givingProvider") {
      setGivingError(false);
    }
  }

  if (isLoading) {
		return <div className="container details loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        {/* <h1>You’re all done!</h1> */}
        <div className="not-on-mobile" style={{ height: '35px' }}></div>
        <div style={{ height: '15px' }}></div>
        <p>Before we get to your report, please confirm<br/>your details so that we can email you a copy.</p>

        <div className="form">

          <div className="form-detail">
            <p>First name</p>
            <TextField hiddenLabel placeholder="Simon" value={firstName} onFocus={() => removeHintText("firstName")} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p>Last name</p>
            <TextField hiddenLabel placeholder="Peter" value={lastName} onFocus={() => removeHintText("lastName")} onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>
          
          {/* <div className="form-detail">
            <p>Full name</p>
            <TextField hiddenLabel placeholder="Simon Peter" value={fullName} onFocus={() => removeHintText("fullName")} onChange={(e) => {updateFullName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("fullName")}
          </div> */}

          <div className="form-detail">
            <p>Email</p>
            <TextField hiddenLabel placeholder="simon@rockchurch.com" disabled={isMember} value={email} onFocus={() => removeHintText("email")} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          {/* <div className="form-detail">
            <p>Job title</p>
            <TextField select hiddenLabel value={jobTitle} onFocus={() => removeHintText("jobTitle")} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            {showHintText("jobTitle")}
          </div> */}

          <div className="form-detail">
            <p>Job title</p>
            <TextField hiddenLabel placeholder="Chief Executive Officer" value={jobTitle} onFocus={() => removeHintText("jobTitle")} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("jobTitle")}
          </div>

          {
            !isMember ? (
              <div className="form-detail">
                <p>Church/Organization name</p>
                <TextField hiddenLabel placeholder="Rock Church" value={churchName} onFocus={() => removeHintText("churchName")}  onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          }

          {
            !isMember ? (
              <div className="form-detail">
                <p>ZIP code</p>
                <TextField hiddenLabel placeholder="11618" value={zipCode} onFocus={() => removeHintText("zipCode")} onChange={(e) => {updateZipCode(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("zipCode")}
              </div>
            ) : null
          }
          
          <div className="cta-btn">
            <Button onClick={handleSubmit} disabled={isSaving}>
              {showButton()}
            </Button>
          </div>

        </div>
        

      </div>
      
    </div>
  );
  
}
export default Details;