import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Question from '../Components/Questions';
// import QuestionTemp from '../Components/QuestionsTemp';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

/*let questions = [
  {
    id: 102,
    question: "How hopeful or cynical is your workplace?",
    answer: 0,
    type: "slider",
    answers: [
      {
        id: 1,
        desktop_text: "Very<br/>Cynical",
        mobile_text: "Very<br/>Cynical",
        value: 1
      },
      {
        id: 2,
        desktop_text: "Cynical",
        mobile_text: "Cynical",
        value: 2
      },
      {
        id: 3,
        desktop_text: "Neutral",
        mobile_text: "Neutral",
        value: 3
      },
      {
        id: 4,
        desktop_text: "Hopeful",
        mobile_text: "Hopeful",
        value: 4
      },
      {
        id: 5,
        desktop_text: "Very<br/>Hopeful",
        mobile_text: "Very<br/>Hopeful",
        value: 5
      }
    ],
    answerTxt: ""
  },
  {
    id: 103,
    question: "How are you currently combating cynicism in your ministry?",
    answer: "",
    type: "text",
    answers: [],
    answerTxt: ""
  },
  {
    id: 104,
    question: "What prevents your church/organization from accelerating a culture<br/>of sustained hope in long-term service?",
    answer: "",
    type: "text",
    answers: [],
    answerTxt: ""
  }
]*/

const AssessmentAdditional = () => {

  let navigate      = useNavigate();
  const { id }      = useParams();

  useEffect(() => {
    // document.body.style.backgroundColor = "#52BB66";
    getAdditional();
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [questions, setQuestions] = React.useState([]);
  const [questionCount, setQuestionCount] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [prevValue, setPrevValue] = React.useState(0);
  const [textInput, setTextInput] = React.useState("");
  
  // const [nextDisabled, setNextDisabled] = React.useState(true);

  function getAdditional() {

    let clientId              = process.env.REACT_APP_CLIENT_ID;
    
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);        
        setIsLoading(false);
        // setActiveStep(data.questions.length - 1);
        // setPrevValue(data.questions[data.questions.length - 1].answer);
			} else {
				// setOpenSnackbar(true);
			}
		});

  }

  function completeAssessmentAdditional() {

    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/additional', {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				questions
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        if(data.member == true) {
          navigate("/details/" + id + '?member=' + data.memberId, { replace: true });
        } else {
          navigate("/details/" + id, { replace: true });
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const prevQuestion = () => {
    if(activeStep == 0) {
      navigate("/assessment/" + id + '/review', { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  const nextQuestion = () => {
    if(activeStep < questions.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
        setTextInput("");
      }, 20);
    } else {
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  };

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions.length - 1) {
      questions[activeStep].answer = answer;      
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    } else {      
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  }

  const updateTextQuestion = (answer) => {
    setTextInput(answer);
  }

  const nextDisabled = (answer) => {
    if(questions[activeStep].mandatory == "no") {
      return false;
    } else {
      if(questions[activeStep].type == "slider" && questions[activeStep].answer != 0) {
        return false;
      } else if(questions[activeStep].type == "textarea" && textInput != "") {
        return false;
      }
    } 
    return true;
  }
  


  const getStepContent = (step) => {
    // if(step == 0) {
      return (
        <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} textAnswer={updateTextQuestion} currentValue={prevValue}/>
      );
    // } else {
    //   return <div>
    //     <div className="question textarea">
    //       <p dangerouslySetInnerHTML={{__html: questions[step].question}}></p>
    //     </div>
    //     <div className="textarea-field">
    //       <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textField}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
    //     </div>
    //   </div>
    // }
    
  };

  if (isLoading) {
		return <div className="container assessment-additional loading"><CircularProgress  style={{'color': '#ffffff'}} size={60} /></div>
	}

  return (
    <div className="container assessment-additional">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">
              <div className="spacer"></div>

              <h1>3 QUICK QUESTIONS ABOUT YOUR MINISTRY</h1>

              <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;<span>Prev</span>
                </Button>
                <Button onClick={nextQuestion} disabled={nextDisabled()}>
                  {
                    (questions.length - 1) == activeStep ? (
                      <div className="cta-btn">
                        <Button>You're all set</Button>
                      </div>
                    )
                    : (
                      <>
                        <span>Next</span>&nbsp;&nbsp;<img src={nextArrow} />
                      </>
                    )
                  }
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default AssessmentAdditional;