import React from 'react';
import { Fragment } from 'react';
import { Link } from "react-router-dom";

import Logo from '../../Assets/Images/logo-client.png';

const Header = props => {
  return <Fragment>
    <header id="header">
      <div className="assessment-title" id="assessment-title">Are you Disillusioned?</div>
    </header>
  </Fragment>
}

export default Header;