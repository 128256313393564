import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import testimonialImage from '../Assets/Images/testimonial.jpg';
import quotationImage from '../Assets/Images/quotation.png';
import sendInviteImage from '../Assets/Images/send-invite.png';
import scheduleCallImage from '../Assets/Images/schedule-icon.png';
import amazonLogo from '../Assets/Images/amazon-logo.png';
import audibleLogo from '../Assets/Images/audible-logo.png';
import lifewayLogo from '../Assets/Images/lifeway-logo.png';
import barnesNobleLogo from '../Assets/Images/barnes-noble-logo.png';
import christianBookLogo from '../Assets/Images/christian-book-logo.png';
import peterGreerLogo from '../Assets/Images/peter-greer-logo.png';

import '../App.scss';

const Results = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const { pathname, hash, key }       = useLocation();

  const [isLoading, setIsLoading]     = React.useState(true);
  const [isMember, setIsMember]       = React.useState(false);
  const [score, setScore]             = React.useState(0);
  const [sections, setSections]       = React.useState([]);
  const [subScore1, setSubScore1]     = React.useState(0);
  const [subScore2, setSubScore2]     = React.useState(0);
  const [subScore3, setSubScore3]     = React.useState(0);
  const [subScore4, setSubScore4]     = React.useState(0);
  const [subScore5, setSubScore5]     = React.useState(0);
  const [subScore6, setSubScore6]     = React.useState(0);
  const [subScore7, setSubScore7]     = React.useState(0);
  const [subScore8, setSubScore8]     = React.useState(0);
  const [name, setName]               = React.useState("");

  const sectionsScores                = [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8];

  useEffect(() => {
    // document.body.style.backgroundColor = "#292829";
    // document.getElementById('header').style.backgroundColor = "#292829";
    
    fetchResults();
  }, []);

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {				
				setScore(data.score);
				setSubScore1(data.subScore1);
				setSubScore2(data.subScore2);
				setSubScore3(data.subScore3);
				setSubScore4(data.subScore4);
				setSubScore5(data.subScore5);
				setSubScore6(data.subScore6);
				setSubScore7(data.subScore7);
				setSubScore8(data.subScore8);
        setIsMember(data.isTeamMember);
        setSections(data.sections);
        setName(data.name);
        setIsLoading(false);
        if (hash === '') {
          window.scrollTo(0, 0);
        } else {
          console.log(hash);
          setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView();
            }
          }, 50);
        }
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  const showTitleText = () => {
    let secScores         = [];
    sections.forEach((section, index) => {
      secScores.push({
        "title": sections[index].section,
        "score": sectionsScores[index]
      })
    });
    
    let topScore          = secScores[0];    
    secScores.forEach(sec => {
      if(sec.score > topScore.score) {
        topScore          = sec;
      }
    });
    
    if(topScore.title == "IDEALISTIC") {
      return <h1>{name}, you’re in the ‘<span>Idealistic</span>’ stage<br/>of your leadership journey</h1>
    } else if(topScore.title == "DISILLUSIONED") {
      return <h1>{name}, you’re in the ‘<span>Disillusioned</span>’ stage<br/>of your leadership journey</h1>
    } else if(topScore.title == "CYNICAL") {
      return <h1>{name}, you’re in the ‘<span>Cynical</span>’ stage<br/>of your leadership journey</h1>
    } else {
      return <h1>{name}, you’re in the ‘<span>Hopeful</span>’ stage<br/>of your leadership journey</h1>
    }
  }

  const showScoreText = () => {

    let secScores         = [];
    sections.forEach((section, index) => {
      secScores.push({
        "title": sections[index].section,
        "score": sectionsScores[index]
      })
    });
    
    let topScore          = secScores[0];    
    secScores.forEach(sec => {
      if(sec.score > topScore.score) {
        topScore          = sec;
      }
    });

    if(topScore.title == "IDEALISTIC") {
      return <div className="result-text">
        <p>It looks like you are currently fueled by idealism. This is an exciting stage of ministry, where
        it’s easy to believe that your work matters and to find the motivation to press onward.</p>

        <p>God works powerfully through this stage of the journey, but it’s not too soon to prepare for what
        likely lies ahead: disillusionment.</p>

        <p>You can take additional steps now to ensure you’re armed with a strategy for combating cynicism
        in your church/organization.</p>
      </div>
    } else if(topScore.title == "DISILLUSIONED") {
      return <div className="result-text">
        <p>It looks like you are currently wrestling with disillusionment. This can be a painful and unwelcome season.</p>

        <p>As you sit with the discomfort of disillusionment, we invite you to seize the opportunity it offers to let
        go of illusions you once believed to be true.</p>

        <p>This is the time to discover the gift of disillusionment and kindle a hope that endures.</p>
      </div>
    } else if(topScore.title == "CYNICAL") {
      return <div className="result-text">
        <p>It looks like you are currently waylaid by cynicism. When we hope in ourselves, our abilities,
        or our team, cynicism is always looming one misstep or disappointment away.</p>

        <p>Hope is not lost, and even in a place of cynicism, we invite you to take the next steps toward
        rekindling a posture of hope.</p>
      </div>
    } else {
      return <div className="result-text">
        <p>It looks like you have embraced the gift of disillusionment and cultivated a rooted hope.
        We have the utmost respect for leaders who maintain this enduringly hopeful posture for the long haul.</p>

        <p>As you have likely discovered, our stage on this journey from idealism to hope is not static.</p>

        <p>We invite you to grow in your awareness of the journey as you continue to root your hope in God.</p>
      </div>
    }
  }

  const showBoldClass = (i) => {

    let secScores         = [];
    sections.forEach((section, index) => {
      secScores.push({
        "title": sections[index].section,
        "score": sectionsScores[index]
      })
    });
    
    let topScore          = secScores[0];    
    secScores.forEach(sec => {
      if(sec.score > topScore.score) {
        topScore          = sec;
      }
    });

    if(topScore.title == "IDEALISTIC" && i == 0) {
      return "bold";
    } else if(topScore.title == "DISILLUSIONED" && i == 1) {
      return "bold";
    } else if(topScore.title == "CYNICAL" && i == 2) {
      return "bold";
    } else if(topScore.title == "HOPEFUL" && i == 3) {
      return "bold";
    }
    return "";
  }

  const showInvite = () => {
    // if(isMember) {
    //   return null;
    // } else {
      return <div className="invite-container">

        <div className="height20"></div>

        Invite a team member or a friend to take this test and help them discover
        where they are on the journey to combating cynicism in ministry.

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>Invite Your team</Button>
          </Link>
        </div>

      </div>
    // }
  }

  const showTeamMember = () => {
    if(isMember) {
      return <div className="invite-container">
        Please get in touch with your team leader for the next step<br/><br/>
      </div>
    } else {
      return null;
    }
  }

  const showSchedule = () => {
    if(isMember) {
      return null;
    } else {
      return <div className="schedule-container">

        <img src={scheduleCallImage} /><br/><br/>
        <div className="height20"></div>

        Schedule a call to have our team walk you through your
        report and see how you can accelerate generosity in your church.
          
        <div className="cta-container">
          <BootstrapTooltip title="Please note that clicking this button will submit your request to talk to us." placement="left-end">
            <InfoIcon/>
          </BootstrapTooltip>
          <div className="cta-btn">
            <a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
              <Button>Request a call</Button>
            </a>
          </div>
        </div>
      </div>
    }
  }

  const buyBook = () => {
    // if(isMember) {
    //   return null;
    // } else {
      return <div className="buy-container" id="buy">
        <div className="buy-left">

        </div>
        <div className="buy-right">

          <h3>Buy the book</h3>
          <p>Take the next step and get started on the journey to rekindling enduring hope in your ministry.</p>

          <span>Shop now:</span>
          <div>
            <a target="_blank" href="https://www.amazon.com/Gift-Disillusionment-Enduring-Leaders-Idealism/dp/0764238264/ref=sr_1_1?crid=3CMFG6FFCBGQE&keywords=the+gift+of+disillusionment&qid=1649779841&sprefix=the+gift+of+dis,aps,115&sr=8-1&fbclid=IwAR1HhThVMcIRBOfzmXwPMDT3nk2AKRz07sH-lpvL7mRMlATzik2sR_fCcr4" className="shop shop-size-1"><img className="amazon" src={amazonLogo} /></a>
            <a target="_blank" href="https://www.audible.com/pd/The-Gift-of-Disillusionment-Audiobook/B09WZ9LM7Y" className="shop shop-size-1"><img className="audible" src={audibleLogo} /></a>
            <a target="_blank" href="https://www.lifeway.com/en/product/the-gift-of-disillusionment-P008016665?CMPID=Adwords-sb-PLA-008016665&ef_id=Cj0KCQjwxtSSBhDYARIsAEn0thQZ2HEoQV5REavMgM4Oif9AfrpnH1mO3Nqn3DDqGOJ59cZjyvCCou8aAr-EEALw_wcB:G:s&s_kwcid=AL!4443!3!566001104289!!!u!294505072980!!15447113038!131329344438&gclid=Cj0KCQjwxtSSBhDYARIsAEn0thQZ2HEoQV5REavMgM4Oif9AfrpnH1mO3Nqn3DDqGOJ59cZjyvCCou8aAr-EEALw_wcB&fbclid=IwAR12WbwXmSuC2mWhL0eVMRg9LfGqB5j2Bm9EqOdzR-aX_XfPxDqsxSA2WfA" className="shop shop-size-1"><img className="lifeway" src={lifewayLogo} /></a>
            <a target="_blank" href="https://www.barnesandnoble.com/w/the-gift-of-disillusionment-peter-greer/1139650769;jsessionid=D462DA4C4AA4BBEB119FCDC415BAC415.prodny_store02-atgap06?ean=9780764238260&fbclid=IwAR2Z-AM7NWoYH3TosvFsOxCWqaRetPywQw40w8FvvvL4xXxu5X9vJI7eI80" className="shop shop-size-1"><img className="barnesnoble" src={barnesNobleLogo} /></a>
            <a target="_blank" href="https://www.christianbook.com/disillusionment-enduring-leaders-after-idealism-fades/peter-greer/9780764238260/pd/238264?product_redirect=1&search_term=greer%20horst&Ntt=238264&item_code=&ps_exit=PRODUCT%7Clegacy&Ntk=keywords&event=ESRCP&fbclid=IwAR0qWgAp-D58MSh0fG-M-_xxPJUwyCMb-J0qXpbu5WbFX7msC0MkPUL8YjM" className="shop shop-size-1"><img className="christianbooks" src={christianBookLogo} /></a>
            <a target="_blank" href="https://www.peterkgreer.com/books/the-gift-of-disillusionment/" className="shop shop-size-1"><img className="petergreer" src={peterGreerLogo} /></a>
          </div>
        
          {/* <div className="cta-container">
            <div className="cta-btn">
              <a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
                <Button>BUY THE BOOK</Button>
              </a>
            </div>
          </div> */}
        </div>

        {/* <img src={scheduleCallImage} /><br/><br/>
        <div className="height20"></div>

        Schedule a call to have our team walk you through your
        report and see how you can accelerate generosity in your church.
          
        <div className="cta-container">
          <BootstrapTooltip title="Please note that clicking this button will submit your request to talk to us." placement="left-end">
            <InfoIcon/>
          </BootstrapTooltip>
          <div className="cta-btn">
            <a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
              <Button>Request a call</Button>
            </a>
          </div>
        </div> */}
      </div>
    // }
  }

  const showSubScore = (i) => {
    if(i == 0) {
      return subScore1;
    } else if(i == 1) {
      return subScore2;
    } else if(i == 2) {
      return subScore3;
    } else if(i == 3) {
      return subScore4;
    } else if(i == 4) {
      return subScore5;
    } else if(i == 5) {
      return subScore6;
    } else if(i == 6) {
      return subScore7;
    } else if(i == 7) {
      return subScore8;
    }
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '15px',
      fontFamily: 'degular'
    },
  }));

  if (isLoading) {
		return <div className="container results loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}

  return (
    <div>
      <div className="container results">
        
        <div className="details-container">
          
        {showTitleText()}

        {/* <div className="progress-bar">
          <div className="si_ass_result"> 
            <div className="si_ass_progress">
              <div className="progress-line" style={{ left: score + '%'}}></div>
              <span className="msi_score" style={{ left: score + '%'}}>{score}%</span>
            </div>
            <div className="pervalue">
              <h4 className="nimp" style={{ minWidth: '45%' }}></h4> 
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
            </div> 
            <div className="si_pro_header">
              <h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br/>improvement</h4> 
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br/>expectations</h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br/>expectations</h4>
            </div> 
          </div>
        </div> */}

        <div className="result-sections">

          {
            sections.map((data, index) => (
              <div className="score-section">                
                <div className="score-txt">{showSubScore(index)}%</div>
                <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={showSubScore(index)} /></div>
                <span className={showBoldClass(index)}>{data.section}</span><br/><br/>
              </div>
            ))
          }

        </div>

        {showScoreText()}

        <div className="adjust45"></div>
  
      </div>
        
    </div>
    <div className="background-wrapper">
      {buyBook()}
      {showInvite()}
      {/* {showTeamMember()} */}

      <div className="testimonal-container">

        <div className="testimonial">
          <span className="quotation-mark">“</span>Our idealism will one day undoubtedly be met with the realities of living in a fallen world.
          These realities will barrage us with waves that not only challenge our missional resolve but
          test the very vitality of our faith. Yet, despite the cynicism, heartache, pain, and discouragement,
          Peter and Chris challenge us to forge ahead because God is at work!<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Chilobe Kalambo<br/>
            <span>Managing member, Kalambo Consulting</span>
          </div>
        </div>

      </div>
    </div>
      
    {/* {showSchedule()} */}

    </div>
  );
  
}
export default Results;