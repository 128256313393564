import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TickImg from '../Assets/Images/thank-you-tick.png'
import { Link, useParams } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import amazonLogo from '../Assets/Images/amazon-logo.png';
import audibleLogo from '../Assets/Images/audible-logo.png';
import lifewayLogo from '../Assets/Images/lifeway-logo.png';
import barnesNobleLogo from '../Assets/Images/barnes-noble-logo.png';
import christianBookLogo from '../Assets/Images/christian-book-logo.png';
import peterGreerLogo from '../Assets/Images/peter-greer-logo.png';

const InviteThankYou = () => {

	const { id }                   	= useParams();
	
	useEffect(() => { 
		// document.body.style.backgroundColor = "#292829";
    // document.getElementById('header').style.backgroundColor = "#292829";
	},[]);

  const buyBook = () => {
    return <div className="buy-container" id="buy">
      <div className="buy-left">

      </div>
      <div className="buy-right">

        <h3>Buy the book</h3>
        <p>Take the next step and get started on the journey to rekindling enduring hope in your ministry.</p>

        <span>Available now:</span>
        <div>
          <a target="_blank" href="https://www.amazon.com/Gift-Disillusionment-Enduring-Leaders-Idealism/dp/0764238264/ref=sr_1_1?crid=3CMFG6FFCBGQE&keywords=the+gift+of+disillusionment&qid=1649779841&sprefix=the+gift+of+dis,aps,115&sr=8-1&fbclid=IwAR1HhThVMcIRBOfzmXwPMDT3nk2AKRz07sH-lpvL7mRMlATzik2sR_fCcr4" className="shop shop-size-1"><img className="amazon" src={amazonLogo} /></a>
          <a target="_blank" href="https://www.audible.com/pd/The-Gift-of-Disillusionment-Audiobook/B09WZ9LM7Y" className="shop shop-size-1"><img className="audible" src={audibleLogo} /></a>
          <a target="_blank" href="https://www.lifeway.com/en/product/the-gift-of-disillusionment-P008016665?CMPID=Adwords-sb-PLA-008016665&ef_id=Cj0KCQjwxtSSBhDYARIsAEn0thQZ2HEoQV5REavMgM4Oif9AfrpnH1mO3Nqn3DDqGOJ59cZjyvCCou8aAr-EEALw_wcB:G:s&s_kwcid=AL!4443!3!566001104289!!!u!294505072980!!15447113038!131329344438&gclid=Cj0KCQjwxtSSBhDYARIsAEn0thQZ2HEoQV5REavMgM4Oif9AfrpnH1mO3Nqn3DDqGOJ59cZjyvCCou8aAr-EEALw_wcB&fbclid=IwAR12WbwXmSuC2mWhL0eVMRg9LfGqB5j2Bm9EqOdzR-aX_XfPxDqsxSA2WfA" className="shop shop-size-1"><img className="lifeway" src={lifewayLogo} /></a>
          <a target="_blank" href="https://www.barnesandnoble.com/w/the-gift-of-disillusionment-peter-greer/1139650769;jsessionid=D462DA4C4AA4BBEB119FCDC415BAC415.prodny_store02-atgap06?ean=9780764238260&fbclid=IwAR2Z-AM7NWoYH3TosvFsOxCWqaRetPywQw40w8FvvvL4xXxu5X9vJI7eI80" className="shop shop-size-1"><img className="barnesnoble" src={barnesNobleLogo} /></a>
          <a target="_blank" href="https://www.christianbook.com/disillusionment-enduring-leaders-after-idealism-fades/peter-greer/9780764238260/pd/238264?product_redirect=1&search_term=greer%20horst&Ntt=238264&item_code=&ps_exit=PRODUCT%7Clegacy&Ntk=keywords&event=ESRCP&fbclid=IwAR0qWgAp-D58MSh0fG-M-_xxPJUwyCMb-J0qXpbu5WbFX7msC0MkPUL8YjM" className="shop shop-size-1"><img className="christianbooks" src={christianBookLogo} /></a>
          <a target="_blank" href="https://www.peterkgreer.com/books/the-gift-of-disillusionment/" className="shop shop-size-1"><img className="petergreer" src={peterGreerLogo} /></a>
        </div>
        
      </div>      
    </div>
  }

	const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '15px',
      fontFamily: 'degular'
    },
  }));

	return (
		<>
			<div className="container thank-you">
				<div className="details-container">
					<div className="tick"><img src={TickImg} alt="" /></div>
					<h1>You’ve successfully invited your team</h1>
					{/* <p>You'll be notified each time one of your team members takes the test<br/>
					so that you can intentionally simplify generosity in your church.</p> */}
					<div className="back-btn">
						<Link to={"/results/" + id}>go back to my results</Link>
					</div>
				</div>
			</div>

      {buyBook()}
	
			{/* <div className="schedule-container">

        <img src={scheduleCallImage} /><br/><br/>
        <div className="height20"></div>

        Schedule a call to have our team walk you through your
        report and see how you can accelerate generosity in your church.
          
        <div className="cta-container">
          <BootstrapTooltip title="Please note that clicking this button will submit your request to talk to us." placement="left-end">
            <InfoIcon/>
          </BootstrapTooltip>
          <div className="cta-btn">
            <a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
              <Button>Request a call</Button>
            </a>
          </div>
        </div>
      </div> */}
		</>
	);
};

export default InviteThankYou;
