import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Link, useParams } from "react-router-dom";
import Tabs from './Tabs';
import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import '../App.scss'

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import '../App.scss';

const Dashboard = (props) => {
  
	const { id }                        			= useParams();

	const [isLoading, setIsLoading]   				= React.useState(true);
	const [currentView, setCurrentView] 			= React.useState(0);

  const [toggleSection, setToggleSection]		= React.useState(1);

	const [members, setMembers]        				= React.useState([]);
	const [selected, setSelected] 						= React.useState(0);
	const [questions, setQuestions]   				= React.useState([]);
	const [hurdles, setHurdles]        				= React.useState([]);
	const [enablers, setEnablers]     				= React.useState([]);
	const [tops, setTops]     								= React.useState([]);
	const [bottoms, setBottoms]     					= React.useState([]);
	
	const [score, setScore]             			= React.useState(0);
  const [subScore1, setSubScore1]     			= React.useState(0);
  const [subScore2, setSubScore2]     			= React.useState(0);
  const [subScore3, setSubScore3]     			= React.useState(0);
  const [subScore4, setSubScore4]     			= React.useState(0);
  const [urgencyScore, setUrgencyScore]			= React.useState(0);
	const [memberSection1, setMemberSection1] = React.useState([]);
	const [memberSection2, setMemberSection2] = React.useState([]);
	const [memberSection3, setMemberSection3] = React.useState([]);
	const [memberSection4, setMemberSection4] = React.useState([]);

	const [memberScore, setMemberScore] 			= React.useState(0);
	const [memberName, setMemberName] 				= React.useState(0);
  const [memSubScore1, setMemSubScore1]			= React.useState(0);
  const [memSubScore2, setMemSubScore2]			= React.useState(0);
  const [memSubScore3, setMemSubScore3]			= React.useState(0);
  const [memSubScore4, setMemSubScore4]			= React.useState(0);
  const [memUrgencyScore, setMemUrgencyScore]	= React.useState(0);
	

	useEffect(() => {
    document.body.style.backgroundColor = "#1F2A44";
    document.getElementById('assessment-title').style.color = "#FBF9F2";
    fetchResults(); 
  }, []);

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/dashboard/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setScore(data.score);
				setSubScore1(data.subScore1);
				setSubScore2(data.subScore2);
				setSubScore3(data.subScore3);
				setSubScore4(data.subScore4);
				setUrgencyScore(data.urgencyScore);
				setMembers(data.assessmentInfo);
				setQuestions(data.questionInfo);
				setHurdles(data.hurdles);
				setEnablers(data.enablers);
				setIsLoading(false);

				setSelected(data.assessmentInfo[0].id);
				setMemberScore(data.assessmentInfo[0].score);
				setMemberName(data.assessmentInfo[0].fullName);
				setMemSubScore1(data.assessmentInfo[0].subScore1);
				setMemSubScore2(data.assessmentInfo[0].subScore2);
				setMemSubScore3(data.assessmentInfo[0].subScore3);
				setMemSubScore4(data.assessmentInfo[0].subScore4);
				setMemUrgencyScore(data.assessmentInfo[0].addAnswer1);

				let section1Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 1; });
				section1Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection1(section1Questions);
				
				let section2Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 2; });
				section2Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection2(section2Questions);

				let section3Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 3; });
				section3Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection3(section3Questions);
				
				let section4Questions 		= data.questionInfo.filter(function (obj) { return obj.sectionId == 4; });
				section4Questions.forEach((question) => {
					let answer 							= data.assessmentInfo[0].answers.find(function (obj) { return obj.question_id == question.id});
					question.answer 				= answer.answer;
				});
				setMemberSection4(section4Questions);
				
				let tops 					= data.questionTop.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
				let bottoms 			= data.questionBottom.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));

				setTops(tops.slice(0, 3));
				setBottoms(bottoms.slice(0, 3));

				console.log(tops);
				console.log(bottoms);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

	const showTitleText = () => {
    if(score > 70) {
      return <h1>Your church exceeds expectations</h1>
    } else if(score > 45) {
      return <h1>Your church meets expectations</h1>
    } else {
      return <h1>Your church needs improvement</h1>
    }
  }

  const showScoreText = () => {
    if(score > 70) {
      return <div className="result-text">
        Congratulations! It looks like your church is doing a great job at ensuring it stays relevant for congregants.
      </div>
    } else if(score > 45) {
      return <div className="result-text">
        While you are on a journey to ensuring your church does not become irrelevant, there's room for making it more relevant for your congregants.
      </div>
    } else {
      return <div className="result-text">
        It looks like your church needs<br/> to work towards becoming more relevant for congregants.
      </div>
    }
  }

	const toggleTeamIndividual = (tab) => {
		if(tab == 1) {
			setCurrentView(0);
		} else {
			setCurrentView(1);
		}
	}

	const updateIndividualAssessment = (e) => {
		let member 				= members.find(function (obj) { return obj.id == e.target.value; });
		console.log(member);
		setSelected(member.id);
		setMemberScore(member.score);
		setMemberName(member.fullName);
		setMemSubScore1(member.subScore1);
		setMemSubScore2(member.subScore2);
		setMemSubScore3(member.subScore3);
		setMemSubScore4(member.subScore4);
		setMemUrgencyScore(member.addAnswer1);
		
		let section1Questions 		= questions.filter(function (obj) { return obj.sectionId == 1; });
		section1Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});

		setMemberSection1(section1Questions);
		
		let section2Questions 		= questions.filter(function (obj) { return obj.sectionId == 2; });
		section2Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection2(section2Questions);

		let section3Questions 		= questions.filter(function (obj) { return obj.sectionId == 3; });
		section3Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection3(section3Questions);
		
		let section4Questions 		= questions.filter(function (obj) { return obj.sectionId == 4; });
		section4Questions.forEach((question) => {
			let answer 							= member.answers.find(function (obj) { return obj.question_id == question.id});
			question.answer 				= answer.answer;
		});
		setMemberSection4(section4Questions);

	}

	const showAnswerText = (answer) => {
		if(answer == 1) {
			return <div className="answer false">ABSOLUTELY FALSE</div>
		} else if(answer == 2) {
			return <div className="answer false">LIKELY FALSE</div>
		} else if(answer == 3) {
			return <div className="answer neutral">UNSURE</div>
		} else if(answer == 4) {
			return <div className="answer true">LIKELY TRUE</div>
		} else if(answer == 5) {
			return <div className="answer true">ABSOLUTELY TRUE</div>
		}
	}

	const toggleSectionDetails = (id) => {
		if(id == toggleSection) {
			setToggleSection(0);
		} else {
			setToggleSection(id);
		}
	}

	const toggleSectionDisplay = (id) => {
		if(id == toggleSection) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleSectionIcon = (id) => {
		if(id == toggleSection) {
			return <RemoveIcon/>
		} else {
			return <AddIcon/>
		}
	}

  if (isLoading) {
		return <div className="container dashboard loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}
	if(currentView == 1) {
		return (
			<div>
				<div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>

				<div className="container results">

					<div style={{ height: '20px' }}></div>
					<div className="selection-form">
						<div className="form-detail">
							<TextField select hiddenLabel value={selected} onChange={(e) => {updateIndividualAssessment(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
								{members.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.fullName}
									</MenuItem>
								))}
							</TextField>
						</div>
						<div className="form-text">Assessment Scores</div>
					</div>

					<div className="details-container">
          
						<h1>{memberName}</h1>

						<div className="progress-bar">
							<div className="si_ass_result"> 
								<div className="si_ass_progress">
									<div className="progress-line" style={{ left: memberScore + '%'}}></div>
									<span className="msi_score" style={{ left: memberScore + '%'}}>{memberScore}%</span>
								</div>
								<div className="pervalue">
									<h4 className="nimp" style={{ minWidth: '45%' }}></h4> 
									<h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
									<h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
								</div> 
								<div className="si_pro_header">
									<h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br/>improvement</h4> 
									<h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br/>expectations</h4>
									<h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br/>expectations</h4>
								</div> 
							</div>
						</div>

						<div className="result-sections">

							<div className="score-section">
								CONGREGATION<br/><br/>
								<div className="score-txt">{memSubScore1}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memSubScore1} /></div>
							</div>

							<div className="score-section">
								FEEDBACK<br/><br/>
								<div className="score-txt">{memSubScore2}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memSubScore2} /></div>
							</div>

							<div className="score-section">
								SERMON<br/><br/>
								<div className="score-txt">{memSubScore3}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memSubScore3} /></div>
							</div>

							<div className="score-section">
								COMMUNITY<br/><br/>
								<div className="score-txt">{memSubScore4}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={memSubScore4} /></div>
							</div>

						</div>

						<div className="members-answered">
							<p className="section">
								CONGREGATION
								<a className="section-toggle" onClick={() => toggleSectionDetails(1)}>
									{ toggleSectionIcon(1) }
								</a>
							</p>
							<div style={ toggleSectionDisplay(1) }>
								{
									memberSection1.map((data, index) => (
										<div className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer)}</div>
									)
								)}
							</div>
						</div>

						<div className="members-answered">
							<p className="section">
								FEEDBACK
								<a className="section-toggle" onClick={() => toggleSectionDetails(2)}>
									{ toggleSectionIcon(2) }
								</a>
							</p>
							<div style={ toggleSectionDisplay(2) }>
								{
									memberSection2.map((data, index) => (
										<div className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer)}</div>
									)
								)}
							</div>
						</div>

						<div className="members-answered">
							<p className="section">
								SERMON
								<a className="section-toggle" onClick={() => toggleSectionDetails(3)}>
									{ toggleSectionIcon(3) }
								</a>
							</p>
							<div style={ toggleSectionDisplay(3) }>
								{
									memberSection3.map((data, index) => (
										<div className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer)}</div>
									)
								)}
							</div>
						</div>

						<div className="members-answered">
							<p className="section">
								COMMUNITY
								<a className="section-toggle" onClick={() => toggleSectionDetails(4)}>
									{ toggleSectionIcon(4) }
								</a>
							</p>
							<div style={ toggleSectionDisplay(4) }>
								{
									memberSection4.map((data, index) => (
										<div className="answered-detail"><p>{ data.question }</p> {showAnswerText(data.answer)}</div>
									)
								)}
							</div>
						</div>

						<div style={{ height: "40px" }}></div>
						
					</div>
					
				</div>
			</div>
		)
	} else {
		return (
			<div>
				<div className="individual-group">
					<button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
					<button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
				</div>
	
				<div className="container dashboard">
					<div className="details-container">
	
						{showTitleText()}
						
						<div className="progress-bar">
							<div className="si_ass_result"> 
								<div className="si_ass_progress">
									<div className="progress-line" style={{ left: score + '%'}}></div>
									<span className="msi_score" style={{ left: score + '%'}}>{score}%</span>
								</div>
								<div className="pervalue">
									<h4 className="nimp" style={{ minWidth: '45%' }}></h4> 
									<h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
									<h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
								</div> 
								<div className="si_pro_header">
									<h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br/>improvement</h4> 
									<h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br/>expectations</h4>
									<h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br/>expectations</h4>
								</div> 
							</div>
						</div>
	
						{showScoreText()}
	
						<div className="result-sections">
	
							<div className="score-section">
								CONGREGATION<br/><br/>
								<div className="score-txt">{subScore1}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore1} /></div>
							</div>
	
							<div className="score-section">
								FEEDBACK<br/><br/>
								<div className="score-txt">{subScore2}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore2} /></div>
							</div>
	
							<div className="score-section">
								SERMON<br/><br/>
								<div className="score-txt">{subScore3}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore3} /></div>
							</div>
	
							<div className="score-section">
								COMMUNITY<br/><br/>
								<div className="score-txt">{subScore4}%</div>
								<div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore4} /></div>
							</div>
	
						</div>
										
						<div>						
							<table>
								<tr className="header">
									<th className="hash">#</th>
									<th>Name</th>
									<th className="center-cell">Congregation</th>
									<th className="center-cell">Feedback</th>
									<th className="center-cell">Sermon</th>
									<th className="center-cell">Community</th>
									<th>Avg(%)</th>
								</tr>
								{
									members.map((data, index) => (
										<tr>
											<td>{ index + 1 }</td>
											<td className="medium">{data.fullName}</td>
											<td className="center-cell">{data.subScore1}</td>
											<td className="center-cell">{data.subScore2}</td>
											<td className="center-cell">{data.subScore3}</td>
											<td className="center-cell">{data.subScore4}</td>
											<td className="center-cell">{data.score}%</td>
										</tr>
									)
								)}
	
								<tr className='footer'>
									<th colSpan='2' className="church-average">Church Average</th>
									<th className="center-cell">{subScore1}</th>
									<th className="center-cell">{subScore2}</th>
									<th className="center-cell">{subScore3}</th>
									<th className="center-cell">{subScore4}</th>
									<th className="center-cell">{score}%</th>
								</tr>
							</table>
						</div>
					
						<hr/>
	
						<h2>Detailed Report:</h2>
										
						<div className="most-answered">
							<p className="green">Celebrate what you’re doing well</p>
							{
								tops.map((data, index) => (
									<div className="most-answered-detail">{ data.question }</div>
								)
							)}
						</div>
	
						<div className="most-answered">
							<p className="red">Focus on what needs immediate attention</p>
							{
								bottoms.map((data, index) => (
									<div className="most-answered-detail">{ data.question }</div>
								)
							)}
						</div>
	
						<div className="alignment">
							<Tabs members={members} questions={questions} />
						</div>
	
						<div className="urgency">
							<h2>Team Average Urgency Score</h2>
							<div className="urgency-slider">
								<h3>Participant votes</h3>
								<Slider
									defaultValue={urgencyScore}
									valueLabelDisplay="off"
									step={40}
									min={10}
									max={50}
									value={urgencyScore}
									className="custom-slider"
								/>
								<div className="slider-points">
	
									<div className="score" style={{ left: ((urgencyScore / 78.5) * 100) + '%' }}>{urgencyScore / 10}</div> 
									{/* 78.5 */}
									<div className="top-points" style={{ left: '0%'}}>1</div>
									<div className="top-points" style={{ left: '20%'}}>2</div>
									<div className="top-points" style={{ left: '40%'}}>3</div>
									<div className="top-points" style={{ left: '60%'}}>4</div>
									<div className="top-points" style={{ left: '80%'}}>5</div>
	
									<div className="top-labels" style={{ left: '0%'}}></div>
									<div className="top-labels" style={{ left: '20%'}}></div>
									<div className="top-labels" style={{ left: '40%'}}></div>
									<div className="top-labels" style={{ left: '60%'}}></div>
									<div className="top-labels" style={{ left: '80%'}}></div>
	
									<a className="point" style={{ left: '0%'}}></a>
									<a className="point" style={{ left: '20%'}}></a>
									<a className="point" style={{ left: '40%'}}></a>
									<a className="point" style={{ left: '60%'}}></a>
									<a className="point" style={{ left: '80%'}}></a>
	
								</div>
								<LinearProgress className="custom-bar" variant="determinate" value={0} />
								<div className="slider-labels">
									<div className="label"><p>Not<br/>Urgent</p></div>
									<div className="label" style={{ left: '20%'}}><p>Hardly<br/>urgent</p></div>
									<div className="label" style={{ left: '40%'}}><p>Unsure</p></div>
									<div className="label" style={{ left: '60%'}}><p>Slightly<br/>urgent</p></div>
									<div className="label" style={{ left: '80%'}}><p>Absolutely<br/>urgent</p></div>
								</div>
							</div>
						</div>
	
						<div className="hurdles-enablers">
							<h2 style={{ color: '#FF6645'}}>HURDLES</h2>
							<div className="hurdles">
								<ul>
								{
									hurdles.map((data, index) => (
										<li>{ data.answer }</li>
									)
								)}
								</ul>
							</div>
						</div>
	
						<div className="hurdles-enablers">
							<h2 style={{ color: '#73B76F' }}>ENABLERS</h2>
							<div className="enablers">
								<ul>
								{
									enablers.map((data, index) => (
										<li>{ data.answer }</li>
									)
								)}
								</ul>
							</div>
						</div>
	
						<div style={{ height: "40px" }}></div>
	
					</div>
				
				</div>
	
			</div>
		);
	}
  

}
export default Dashboard;









