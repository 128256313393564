import React, { useState, useEffect } from 'react';

import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';

class Question extends React.Component {

  // const [showSlider, setShowSlider] = useState(false);
  // const [progress, setProgress] = useState(props.currentValue);
  
  constructor(props) {
    super(props);
    
    let sliderWidth       = 86;
    if(this.props.question.answers.length == 2) {
      sliderWidth         = 36;
    }
    
    this.state = {
      showSlider: false,
      progress: 0,
      sliderWidth: sliderWidth,
      maxWidth: this.props.question.answers.length
    }
    
    if(props.currentValue > 0) {
      this.state.progress   = props.currentValue;
    } else if(this.props.question.answer != 0) {
      this.state.progress   = this.props.question.answer;
      this.state.showSlider = true;
    }
  };

  componentWillReceiveProps(nextProps) {
    
    let sliderWidth       = 86;
    if(nextProps.question.answers.length == 2) {
      sliderWidth         = 36;
    } else if(nextProps.question.answers.length == 3) {
      sliderWidth         = 56;
    } else if(nextProps.question.answers.length == 4) {
      sliderWidth         = 76;
    }
    
    this.setState({ sliderWidth: sliderWidth });
    this.setState({ maxWidth: nextProps.question.answers.length });
    
    if(nextProps.currentValue !== 0) {
      this.setState({progress: nextProps.currentValue});
      this.setState({ showSlider: true });      
    } else if(nextProps.currentValue == 0) {
      this.setState({progress: 0});
      this.setState({ showSlider: false });      
    }

  }

  getLeft = (index) => {
    if(this.props.question.answers.length == 2) {
      return index * 80;
    } else if(this.props.question.answers.length == 3) {
      return index * 40;
    } else {
      return index * 20;
    }
  }
  
  updateStep = (progress) => {

    this.setState({showSlider: true});
    setTimeout(() => {this.setState({progress: progress})}, 0);
    this.props.questionAnswered(progress);
    setTimeout(() => {
      this.setState({showSlider: false});
      this.setState({progress: 0});
    }, 500);

  }
  
  getLabelStyle = () => {
    if(window.innerWidth > 650) {

      if(this.props.question.answers.length == 5) {
        return { width: "100%", marginLeft: "-50%" };
      } else if(this.props.question.answers.length == 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if(this.props.question.answers.length == 3) {
        return { width: "200%", marginLeft: "-100%" };
      } else if(this.props.question.answers.length == 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }

    } else {

      if(this.props.question.answers.length == 5) {
        return { width: "120%", marginLeft: "-60%" };
      } else if(this.props.question.answers.length == 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if(this.props.question.answers.length == 3) {
        return { width: "300%", marginLeft: "-150%" };
      } else if(this.props.question.answers.length == 2) {
        return { width: "500%", marginLeft: "-250%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }

    }
    
  }

  checkOpacity = (index) => {
    if(index == 0 && this.state.showSlider) {
      return "0";
    } else {
      return "1";
    }
  }

  checkSelected = (value) => {
    if(this.state.progress == value) {
      return "label active";
    }
    return "label";
  }

  updateQuestion = (event, step) => {
    this.props.question.answerTxt = event.target.value;
    this.setState({answerTxt: event.target.value});
    this.props.textAnswer(event.target.value);
    // return true;
  }

  render() {
    return (
      <div>
              
        <div className="question">
          <p dangerouslySetInnerHTML={{__html: this.props.question.question}}></p>
        </div>
  
        {
          (() => {
            switch(this.props.question.type) {
              case "textarea":
                return <>
                  <div className="textarea-field">
                    <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={this.props.question.answerTxt} onChange={(e) => {this.updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                  </div>
                </>
                break;
              default: 
                return <div className="slider-container">
                  <div className="slider">
                    { this.state.showSlider ? <Slider
                      key={this.props.question.id}
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={this.state.maxWidth}
                      value={this.state.progress}
                      className="custom-slider"
                    /> : <div className="slider-none-adjust"></div> }
                    <div className="slider-points">
                    { 
                      this.props.question.answers.map((data, index) => (
                        <a className="point" key={`points-${index}`} style={{ left: this.getLeft(index) + '%', opacity: this.checkOpacity(index) }} onClick={() => this.updateStep(data.value)}></a>
                      ))
                    }
                    </div>
                    <LinearProgress className="custom-bar" variant="determinate" value={0} />
                    
                  </div>
                  <div className="slider-labels">
                    { 
                      this.props.question.answers.map((data, index) => (
                        <div className={this.checkSelected(data.value)} key={`labels-${index}`} style={{ left: this.getLeft(index) + '%'}} onClick={() => this.updateStep(data.value)}>
                          <p className="desktop" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktop_text }}></p>
                          <p className="mobile" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobile_text }}></p>
                        </div>
                      ))
                    }
                    {/* <div className="label" onClick={() => this.updateStep(1)}><p>Absolutely false</p></div>
                    <div className="label" style={{ left: '20%'}} onClick={() => this.updateStep(2)}><p>Likely false</p></div>
                    <div className="label" style={{ left: '40%'}} onClick={() => this.updateStep(3)}><p>Unsure</p></div>
                    <div className="label" style={{ left: '60%'}} onClick={() => this.updateStep(4)}><p>Likely true</p></div>
                    <div className="label" style={{ left: '80%'}} onClick={() => this.updateStep(5)}><p>Absolutely true</p></div> */}
                  </div>
                </div>
                break;
            }
          }).call(this)
        }
                
      </div>
    );
  }
  
}
export default Question;

// onChange={(e) => {updateQuestion(e, step)}} 