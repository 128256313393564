import React from 'react';
import { Fragment } from 'react';

const Footer = props => {
  return <Fragment>
    <footer>
      
    </footer>
  </Fragment>
}

export default Footer;